import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

import MerchantOnlineBookingPricing from "views/MerchantOnlineBookingPricing/PricingList";
import CreateMerchantOnlineBookingPricing from "views/MerchantOnlineBookingPricing/Create";
import EditMerchantOnlineBookingPricing from "views/MerchantOnlineBookingPricing/Edit";
import DeleteMerchantOnlineBookingPricing from "views/MerchantOnlineBookingPricing/Delete";

const merchantOnlineBookingPricing = () => {
  let views = [];

  views.push({
    path: "/MerchantOnlineBookingPricing/List",
    name: "M Online Booking Pricing",
    icon: MonetizationOnIcon,
    component: MerchantOnlineBookingPricing,
    layout: "/admin"
  });

  return views;
};

const merchantOnlineBookingPricingHidden = () => {
  let views = [];

  views = views.concat([
    {
      path: "/MerchantOnlineBookingPricing/Create",
      name: "Create M Online Booking Pricing",
      component: CreateMerchantOnlineBookingPricing,
      layout: "/admin"
    },
    {
      path: "/MerchantOnlineBookingPricing/Edit",
      name: "Edit M Online Booking Pricing",
      component: EditMerchantOnlineBookingPricing,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/MerchantOnlineBookingPricing/Delete",
      name: "Delete M Online Booking Pricing",
      component: DeleteMerchantOnlineBookingPricing,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    }
  ]);

  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "BookingType Hidden",
        state: "bookingTypeHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { merchantOnlineBookingPricing, merchantOnlineBookingPricingHidden };
