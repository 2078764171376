import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { travelApi } from "functions/apis";
import React, { useEffect, useRef } from "react";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import { Assignment, Check } from "@material-ui/icons";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import ReactTable from "react-table";
import { formatDateTime } from "functions/Helper";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";

const useStyles = makeStyles(styles);
const sortOptionsList = [
  "PRODUCT CODE ASC",
  "PRODUCT CODE DESC",
  "PRODUCT NAME ASC",
  "PRODUCT NAME DESC"
];
export default function MerchantOnlineBookingPricing() {
  const source = useRef(new AbortController());

  const [reload, setReload] = React.useState({});
  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    searchString: "",
    currentActive: false,
    sort: 0
  });

  const getData = () => {
    setLoading(true);
    travelApi
      .get("/OnlinePricings", {
        signal: source.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <>
                    <Link
                      to={
                        "/admin/MerchantOnlineBookingPricing/Edit/" + record.id
                      }
                    >
                      <Button
                        color="danger"
                        className="remove"
                        onClick={() => {}}
                      >
                        <EditIcon /> EDIT
                      </Button>
                    </Link>

                    <Link
                      to={
                        "/admin/MerchantOnlineBookingPricing/Delete/" +
                        record.id
                      }
                    >
                      <Button
                        color="danger"
                        className="remove"
                        onClick={() => {}}
                      >
                        <DeleteIcon /> DELETE
                      </Button>
                    </Link>
                  </>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    return () => {
      source.current.abort();
      setReload({});
    };
  }, []);

  useEffect(() => {
    getData();
  }, [pagination, filter, reload]);

  const handleSearchStringChanged = e => {
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });
  };

  const handleCurrentActiveChanged = e =>
    setFilter(prev => ({
      ...prev,
      currentActive: e.target.checked
    }));

  const handleSortOptionsChanged = e => {
    setFilter(prev => ({ ...prev, sort: e.target.value }));
  };

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              MERCHANT ONLINE BOOKING PRICING
            </h4>
          </CardHeader>
          <CardBody>
            <Link to={"/admin/MerchantOnlineBookingPricing/Create"}>
              <Button color="rose" type="button" value="Create">
                Create
              </Button>
            </Link>
            <GridContainer justifyContent="center" alignItems="center">
              <GridItem xs={5}>
                <CustomInput
                  labelText="Search"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleSearchStringChanged,
                    value:
                      filter.searchString === null ? "" : filter.searchString
                  }}
                />
              </GridItem>
              <GridItem xs={5}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selSortOptions"
                    className={classes.selectLabel}
                  >
                    SORT BY
                  </InputLabel>
                  <Select
                    inputProps={{
                      id: "selSortOptions",
                      name: "selSortOptions"
                    }}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={filter.sort ?? ""}
                    onChange={handleSortOptionsChanged}
                  >
                    {sortOptionsList.map((record, key) => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={key}
                          key={record}
                        >
                          <p>{record}</p>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.currentActive}
                      onChange={handleCurrentActiveChanged}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Current Active"
                />
              </GridItem>
            </GridContainer>
            <ReactTable
              manual
              pages={pages}
              page={pagination.pageIndex - 1}
              pageSize={pagination.pageSize}
              onPageChange={page =>
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                })
              }
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  id: "code",
                  Header: "Code",
                  accessor: d => d.product.code
                },
                {
                  id: "name",
                  Header: "Name",
                  accessor: d => d.product.name
                },
                {
                  id: "startDate",
                  Header: "Start",
                  accessor: d =>
                    d.startDate ? formatDateTime(new Date(d.startDate)) : "-"
                },
                {
                  id: "untilDate",
                  Header: "Until",
                  accessor: d =>
                    d.startDate ? formatDateTime(new Date(d.untilDate)) : "-"
                },
                {
                  id: "Variant",
                  Header: "Variant",
                  accessor: d => d.variant.name
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
