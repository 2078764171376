import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput";
import { travelApi } from "functions/apis";
import { useParams, Redirect } from "react-router-dom";
import { formatCurrency } from "functions/Helper";
import { formatDateTime } from "functions/Helper";
import ViewMerchantOnlineBookingDetail from "./ViewMerchantOnlineBookingDetail";

const useStyles = makeStyles(styles);

const statusList = ["Pending", "Submitted"];

const CompleteMerchantOnlineBooking = props => {
  const classes = useStyles();

  const [input, setInput] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const { id } = useParams();

  const completedMerchantOnlineBookingSignal = useRef(new AbortController());

  useEffect(() => {
    travelApi
      .get("OnlineBookings/" + id, {
        signal: completedMerchantOnlineBookingSignal.current.signal
      })
      .then(resp => {
        setInput(resp);
      });
    return () => completedMerchantOnlineBookingSignal.current.abort();
  }, []);

  const handleCompletePressed = e => {
    e.preventDefault();
    travelApi
      .post("OnlineBookings/" + id + "/SetAsCompleted", null, {
        signal: completedMerchantOnlineBookingSignal.current.signal
      })
      .then(() => {
        setRedirect(true);
      });
  };

  return redirect ? (
    <Redirect to={"/admin/MerchantOnlineBooking/CompletedList"} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              COMPLETED MERCHANT ONLINE BOOKING
            </h4>
          </CardHeader>
          <CardBody>
            <i>
              *Are you sure you want to change the status of this online
              merchant booking to complete?
            </i>
            <form onSubmit={handleCompletePressed}>
              <Button onClick={() => props.history.goBack()} value="Cancel">
                CANCEL
              </Button>
              <Button color="rose" type="submit" value="Submit">
                SET AS COMPLETE
              </Button>
            </form>
            {input != null && (
              <form>
                <GridContainer>
                  <GridItem xs={6}>
                    <CustomInput
                      labelText="Booking No"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: input.no,
                        disabled: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <CustomInput
                      labelText="Status"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: statusList[input.status],
                        disabled: true
                      }}
                    />
                  </GridItem>
                  {input.status == 1 && (
                    <GridItem xs={6}>
                      <CustomInput
                        labelText="Submitted At"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: formatDateTime(
                            new Date(input.submittedAt + "Z")
                          ),
                          disabled: true
                        }}
                      />
                    </GridItem>
                  )}
                  {input.status == 1 && (
                    <GridItem xs={6}>
                      <CustomInput
                        labelText="Submitted By"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: input.submittedBy,
                          disabled: true
                        }}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={6}>
                    <CustomInput
                      labelText="Email Confirmation"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: input.emailConfirmation,
                        disabled: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <CustomInput
                      labelText="Transaction Time"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: formatDateTime(
                          new Date(input.transactionTime + "Z")
                        ),
                        disabled: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <CustomInput
                      labelText="Total"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:
                          input == null
                            ? ""
                            : formatCurrency(
                                input.currency === null
                                  ? "XXX"
                                  : input.currency.code,
                                input.total
                              ),
                        disabled: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <CustomInput
                      labelText="Remarks"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: input.remarks,
                        disabled: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          </CardBody>
        </Card>
        {input &&
          input.details.length > 0 &&
          input.details.map((val, key) => (
            <ViewMerchantOnlineBookingDetail
              key={key}
              data={val}
              currency={input.currency.code}
            />
          ))}
      </GridItem>
    </GridContainer>
  );
};

export default CompleteMerchantOnlineBooking;

CompleteMerchantOnlineBooking.propTypes = {
  history: PropTypes.object
};
