import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ReactTable from "react-table";
import DeleteIcon from "@material-ui/icons/Delete";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { travelApi } from "functions/apis";
import { Checkbox, FormControlLabel, Input } from "@material-ui/core";
import MerchantDialog from "components/MerchantDialog/MerchantDialog";
import { Check } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(styles);

export default function EditMerchantProduct(props) {
  const editMerchantPromoSignal = useRef(new AbortController());
  const [body, setBody] = React.useState(null);

  const { id } = useParams();

  const [showModal, setShowModal] = React.useState(false);
  const [merchantData, setMerchantData] = React.useState({
    id: "",
    name: ""
  });
  const [controlGroupData, setControlGroupData] = React.useState({
    id: "",
    name: "",
    code: ""
  });
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 5
  });
  const [variant, setVariant] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [routeList, setRouteList] = React.useState([]);
  const [ageVariantInput, setAgeVariantInput] = React.useState({
    name: "",
    from: 0,
    to: 0
  });
  const [editID, setEditID] = React.useState();

  const getRouteList = () => {
    travelApi
      .get("routes", {
        params: {
          filter: {
            searchString: ""
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        },
        signal: editMerchantPromoSignal.current.signal
      })
      .then(resp => {
        setRouteList(resp.records);
      })
      .catch(err => console.log(err));
  };

  const getDetailVariant = () => {
    travelApi
      .get(`products/${id}/variants`, {
        signal: editMerchantPromoSignal.current.signal,
        params: {
          filter: {},
          pagination: pagination
        }
      })
      .then(data => {
        setVariant(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <Button
                    onClick={() => {
                      setAgeVariantInput({
                        name: record.name,
                        from: record.ageRangeFrom,
                        to: record.ageRangeTo
                      });
                      setEditID(record.id);
                    }}
                    color="info"
                    className="like"
                  >
                    <EditIcon /> EDIT
                  </Button>
                  <Button
                    onClick={() => onDeleteVariantClicked(record.id)}
                    color="danger"
                    className="remove"
                  >
                    <DeleteIcon /> DELETE
                  </Button>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getDataMerchant = () => {
    travelApi
      .get("products/ferries/" + id, {
        signal: editMerchantPromoSignal.current.signal
      })
      .then(data => {
        setBody({
          bookingPaxInfoType: data.bookingPaxInfoType,
          code: data.code,
          controlGroupID: data.controlGroupID,
          estimateTimeOfArrival: data.estimateTimeOfArrival,
          gateClose: data.gateClose,
          gateOpen: data.gateOpen,
          hasAgeVariant: data.hasAgeVariant,
          hasNoInventory: data.hasNoInventory,
          merchantID: data.merchant.id,
          name: data.name,
          remarks: data.remarks,
          routeID: data.route.id,
          seatClass: data.seatClass,
          time: data.time,
          hasAgeRestriction: data.hasAgeRestriction,
          minimumAllowedAge: data.minimumAllowedAge,
          blockIfDuplicate: data.blockIfDuplicate
        });
        setMerchantData({
          ...merchantData,
          id: data.merchant?.id,
          name: data.merchant?.name
        });
        if (data.hasAgeVariant) {
          getDetailVariant();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRouteList();
    getDataMerchant();
    return () => editMerchantPromoSignal.current.abort();
  }, []);

  useEffect(() => {
    if (body && body.controlGroupID) {
      travelApi
        .get("inventoryControlGroups/" + body.controlGroupID, {
          signal: editMerchantPromoSignal.current.signal
        })
        .then(data => {
          setControlGroupData({
            id: data.id,
            name: data.name,
            code: data.code
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [body]);

  const handleMerchantIDChanged = e => {
    setMerchantData({ ...merchantData, id: e.id, name: e.name });
    setShowModal(false);
  };

  const handleAgeVariantNameChanged = e =>
    setAgeVariantInput({
      ...ageVariantInput,
      name: e.target.value == "" ? null : e.target.value
    });

  const handleAgeVariantFromChanged = e =>
    setAgeVariantInput({
      ...ageVariantInput,
      from: e.target.value ?? 0
    });

  const handleAgeVariantToChanged = e =>
    setAgeVariantInput({
      ...ageVariantInput,
      to: e.target.value ?? 0
    });

  const handleCodeChanged = e =>
    setBody({ ...body, code: e.target.value === "" ? null : e.target.value });
  const handleNameChanged = e =>
    setBody({ ...body, name: e.target.value === "" ? null : e.target.value });
  const handleBookingPaxInfoTypeChanged = e =>
    setBody({
      ...body,
      bookingPaxInfoType: e.target.value === "" ? null : e.target.value
    });
  const handleHasAgeVariantChanged = e =>
    setBody({ ...body, hasAgeVariant: e.target.checked });
  const handleHasNoInventoryChanged = e =>
    setBody({ ...body, hasNoInventory: e.target.checked });
  const handleHasAgeRestrictionChanged = e =>
    setBody({ ...body, hasAgeRestriction: e.target.checked });
  const handleRouteIDChanged = e =>
    setBody({ ...body, routeID: e.target.value });
  const handleGateOpenChanged = e =>
    e.target.value.length < 5 && setBody({ ...body, gateOpen: e.target.value });
  const handleGateCloseChanged = e =>
    e.target.value.length < 5 &&
    setBody({ ...body, gateClose: e.target.value });
  const handleTimeChanged = e =>
    e.target.value.length < 5 && setBody({ ...body, time: e.target.value });
  const handleEstimateTimeOfArrivalChanged = e =>
    e.target.value.length < 5 &&
    setBody({ ...body, estimateTimeOfArrival: e.target.value });
  const handleSeatClassChanged = e =>
    setBody({
      ...body,
      seatClass: e.target.value === "" ? null : e.target.value
    });
  const handleRemarksChanged = e =>
    setBody({
      ...body,
      remarks: e.target.value === "" ? null : e.target.value
    });
  const handleMinimumAllowAgeChanged = e =>
    setBody({
      ...body,
      minimumAllowedAge: e.target.value === "" ? null : e.target.value
    });
  const handleBlockIfDuplicateChanged = e =>
    setBody({ ...body, blockIfDuplicate: e.target.checked });
  const classes = useStyles();

  const onAddVariantClicked = () => {
    travelApi
      .post(
        `products/${id}/variants/add`,
        {
          name: ageVariantInput.name,
          ageRangeFrom: ageVariantInput.from,
          ageRangeTo: ageVariantInput.to
        },
        {
          signal: editMerchantPromoSignal.current.signal
        }
      )
      .then(resp => {
        console.log(resp.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        getDetailVariant();
        setAgeVariantInput({
          from: 0,
          name: "",
          to: 0,
          actions: ""
        });
      });
  };

  const onDeleteVariantClicked = variantID => {
    travelApi
      .delete(`products/${id}/variants/${variantID}`, {
        signal: editMerchantPromoSignal.current.signal
      })
      .then(resp => {
        console.log(resp.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        getDetailVariant();
      });
  };

  const onEditVariantClicked = () => {
    travelApi
      .put(
        `products/${id}/variants/${editID}`,
        {
          name: ageVariantInput.name,
          ageRangeFrom: ageVariantInput.from,
          ageRangeTo: ageVariantInput.to
        },
        {
          signal: editMerchantPromoSignal.current.signal
        }
      )
      .then(resp => {
        console.log(resp);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setEditID();
        setAgeVariantInput({
          from: 0,
          name: "",
          to: 0
        });
        getDetailVariant();
      });
  };

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    var isValid = true;
    let message = "";
    const tempBody = { ...body };

    if (!tempBody.merchantID) {
      isValid = false;
      message = "Please Select Merchant Before Submit";
    }

    if (!tempBody.code || tempBody.code == "") {
      isValid = false;
      message = "Product Code can't be null";
    }

    if (!tempBody.name || tempBody.name == "") {
      isValid = false;
      message = "Product Name can't be null";
    }

    if (!tempBody.bookingPaxInfoType) {
      isValid = false;
      message = "Please Select Booking Pax Info Type Before Submit";
    }

    if (!tempBody.routeID) {
      isValid = false;
      message = "Please Select Route Before Submit";
    }

    if (tempBody.seatClass == "") {
      isValid = false;
      message = "Seat Class can't be null";
    }

    tempBody.merchantID = merchantData.id;
    tempBody.controlGroupID = controlGroupData.id;

    if (tempBody.gateOpen == null || tempBody.gateClose == "") {
      isValid = false;
      message = "Gate Open can't be null";
    }

    if (tempBody.gateClose == null || tempBody.gateClose == "") {
      isValid = false;
      message = "Gate Close can't be null";
    }

    if (tempBody.time == null || tempBody.time == "") {
      isValid = false;
      message = "Time can't be null";
    }

    if (
      tempBody.estimateTimeOfArrival == null ||
      tempBody.estimateTimeOfArrival == ""
    ) {
      isValid = false;
      message = "Estimate Time Of Arrival can't be null";
    }

    if (!tempBody.hasAgeRestriction) {
      tempBody.minimumAllowedAge = 0;
    }

    if (isValid) {
      const resolveFromTravelApi = new Promise((resolve, reject) => {
        if (controlGroupData.code != tempBody.code) {
          travelApi
            .post(
              "inventoryControlGroups",
              {
                name: body.name,
                code: body.code
              },
              {
                signal: editMerchantPromoSignal.current.signal
              }
            )
            .then(resp => {
              if (resp) {
                tempBody.controlGroupID = resp;
                travelApi
                  .put("/products/ferries/" + id, tempBody, {
                    signal: editMerchantPromoSignal.current.signal
                  })
                  .then(() => {
                    resolve({ isSuccess: true });
                  })
                  .catch(error => {
                    reject({ isSuccess: false, error });
                  });
              } else {
                reject({
                  isSuccess: false,
                  error: {
                    response: {
                      data: {
                        title:
                          "The code must be unique. Please enter a different code."
                      }
                    }
                  }
                });
              }
            })
            .catch(error => {
              reject({
                isSuccess: false,
                error
              });
              setDisabled(false);
            });
        } else {
          travelApi
            .put("/products/ferries/" + id, tempBody, {
              signal: editMerchantPromoSignal.current.signal
            })
            .then(() => {
              resolve({ isSuccess: true });
            })
            .catch(error => {
              reject({ isSuccess: false, error });
            });
        }
      });

      toast
        .promise(resolveFromTravelApi, {
          pending: "...Submit data",
          success: {
            render({ data }) {
              if (data.isSuccess) {
                return `Success submit data`;
              } else {
                return `Error submit data: ${JSON.stringify(
                  data.error?.response.data.title
                )}`;
              }
            }
          },
          error: {
            render({ data }) {
              return `Error submit data: ${JSON.stringify(
                data.error?.response.data.title
              )}`;
            }
          }
        })
        .then(resp => {
          if (resp && resp.isSuccess) {
            setRedirect(true);
          }
        })
        .finally(() => {
          setDisabled(false);
        });
    } else {
      toast.error(message);
      setDisabled(false);
    }
  };
  return redirect ? (
    <Redirect to={"/admin/MerchantFerryProducts/InDraft"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4
              className={classes.cardIconTitle}
            >{`EDIT MERCHANT FERRY'S PRODUCTS`}</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            {body && controlGroupData && (
              <form onSubmit={handleSubmit}>
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selAgent"
                        className={classes.selectLabel}
                      >
                        Merchant
                      </InputLabel>
                      <Input
                        classes={{
                          input: classes.input,
                          disabled: classes.active,
                          root: classes.active,
                          underline: classes.underline
                        }}
                        value={merchantData.name ?? ""}
                        onClick={() => setShowModal(true)}
                        fullWidth
                      />
                    </FormControl>
                    <MerchantDialog
                      openModal={showModal}
                      setOpenModal={val => setShowModal(val)}
                      onSelect={handleMerchantIDChanged}
                    />
                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      labelText="Code"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleCodeChanged,
                        value: body.code === null ? "" : body.code
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      labelText="Name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleNameChanged,
                        value: body.name === null ? "" : body.name
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selBusinessCategory"
                        className={classes.selectLabel}
                      >
                        Booking Pax Info Type
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={
                          body.bookingPaxInfoType === null
                            ? ""
                            : body.bookingPaxInfoType
                        }
                        onChange={handleBookingPaxInfoTypeChanged}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={0}
                          key={0}
                        >
                          <b>{"By Quantity Only"}</b>
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={1}
                          key={1}
                        >
                          <b>{"ID Card"}</b>
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={2}
                          key={2}
                        >
                          <b>{"Passport"}</b>
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={3}
                          key={3}
                        >
                          <b>{"No And Name"}</b>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selBusinessCategory"
                        className={classes.selectLabel}
                      >
                        Route
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={body.routeID === null ? "" : body.routeID}
                        onChange={handleRouteIDChanged}
                      >
                        {routeList.map(route => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={route.id}
                              key={route.id}
                            >
                              <b>{route.name}</b>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="SeatClass"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleSeatClassChanged,
                        value: body.seatClass === null ? "" : body.seatClass
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      labelText="GATE OPEN"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: handleGateOpenChanged,
                        value: body.gateOpen === null ? "" : body.gateOpen
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      labelText="GATE CLOSE"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: handleGateCloseChanged,
                        value: body.gateClose === null ? "" : body.gateClose
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      labelText="TIME"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: handleTimeChanged,
                        value: body.time === null ? "" : body.time
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      labelText="ESTIMATE TIME OF ARRIVAL"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: handleEstimateTimeOfArrivalChanged,
                        value:
                          body.estimateTimeOfArrival === null
                            ? ""
                            : body.estimateTimeOfArrival
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} md={12}>
                    <CustomInput
                      labelText="Remarks"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleRemarksChanged,
                        value: body.remarks === null ? "" : body.remarks
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={body.blockIfDuplicate}
                          onChange={handleBlockIfDuplicateChanged}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Block If Duplicate"
                    />
                  </GridItem>

                  <GridItem xs={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={body.hasAgeVariant}
                          onChange={handleHasAgeVariantChanged}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Has Age Variant"
                    />
                  </GridItem>
                  {body.hasAgeVariant && (
                    <GridItem xs={12} md={4}>
                      <CustomInput
                        labelText="Name"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: handleAgeVariantNameChanged,
                          value:
                            ageVariantInput.name === null
                              ? ""
                              : ageVariantInput.name
                        }}
                      />
                    </GridItem>
                  )}
                  {body.hasAgeVariant && (
                    <GridItem xs={12} md={4}>
                      <CustomInput
                        labelText="Range From"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          onChange: handleAgeVariantFromChanged,
                          value:
                            ageVariantInput.from === null
                              ? ""
                              : ageVariantInput.from
                        }}
                      />
                    </GridItem>
                  )}
                  {body.hasAgeVariant && (
                    <GridItem xs={12} md={4}>
                      <CustomInput
                        labelText="Range To"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          onChange: handleAgeVariantToChanged,
                          value:
                            ageVariantInput.to === null
                              ? ""
                              : ageVariantInput.to
                        }}
                      />
                    </GridItem>
                  )}
                  {body.hasAgeVariant && (
                    <GridItem xs={12}>
                      <div style={{ alignSelf: "flex-end" }}>
                        <Button
                          color="rose"
                          onClick={() => {
                            if (editID) {
                              onEditVariantClicked();
                            } else {
                              onAddVariantClicked();
                            }
                          }}
                        >
                          {`${editID ? `Edit` : "Add"} To Variant Table`}
                        </Button>
                      </div>
                    </GridItem>
                  )}
                  {body.hasAgeVariant && (
                    <GridItem xs={12}>
                      <ReactTable
                        manual
                        pages={pages}
                        page={pagination.pageIndex - 1}
                        pageSize={pagination.pageSize}
                        onPageChange={page =>
                          setPagination({
                            pageIndex: page + 1,
                            pageSize: pagination.pageSize
                          })
                        }
                        onPageSizeChange={(pageSize, page) => {
                          setPagination({
                            pageSize: pageSize,
                            pageIndex: page + 1
                          });
                        }}
                        loading={loading}
                        data={variant}
                        filterable={false}
                        columns={[
                          {
                            Header: "Name",
                            accessor: "name"
                          },
                          {
                            Header: "Age Range From",
                            accessor: "ageRangeFrom"
                          },
                          {
                            Header: "Age Range To",
                            accessor: "ageRangeTo"
                          },
                          {
                            Header: "Actions",
                            accessor: "actions",
                            sortable: false,
                            filterable: false
                          }
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className="-striped -highlight"
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={body.hasNoInventory}
                          onChange={handleHasNoInventoryChanged}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Has No Inventory"
                    />
                  </GridItem>
                  <GridItem xs={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={body.hasAgeRestriction}
                          onChange={handleHasAgeRestrictionChanged}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Has Age Restriction"
                    />
                  </GridItem>
                  {body.hasAgeRestriction && (
                    <GridItem xs={12} md={12}>
                      <CustomInput
                        labelText="Minimum Allowed Age"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          onChange: handleMinimumAllowAgeChanged,
                          value:
                            body.minimumAllowedAge === null
                              ? ""
                              : body.minimumAllowedAge
                        }}
                      />
                    </GridItem>
                  )}
                </GridContainer>

                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </form>
            )}
          </CardBody>
        </Card>
        <ToastContainer theme="light" />
      </GridItem>
    </GridContainer>
  );
}

EditMerchantProduct.propTypes = {
  history: PropTypes.object
};
