import React, { useEffect, useRef, useState } from "react";
// react component for creating dynamic tables
import { Redirect, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { MailOutline } from "@material-ui/icons";
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import MerchantProductDialog from "components/MerchantProductDialog/MerchantProductDialog";
import { masterApi } from "functions/apis";
import { travelApi } from "functions/apis";
import CustomInput from "components/CustomInput/CustomInput";

const useStyles = makeStyles(styles);

export default function DeleteMerchantOnlineBookingPricing(props) {
  const source = useRef(new AbortController());
  const [redirect, setRedirect] = useState(false);
  const [payload, setPayload] = useState({
    startDate: null,
    untilDate: null
  });
  const [editData, setEditData] = useState();
  const [showProductModal, setShowProductModal] = React.useState(false);
  const [productData, setProductData] = React.useState({
    id: "",
    name: ""
  });
  const [currencyList, setCurrencyList] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);

  const { id } = useParams();

  useEffect(() => {
    getCurrencyListData();
    return () => {
      source.current.abort();
      if (payload) {
        setRedirect(true);
      }
    };
  }, []);

  useEffect(() => {
    if (id) {
      travelApi
        .get("/OnlinePricings/" + id, {
          signal: source.current.signal
        })
        .then(data => {
          setEditData(data);
          setPayload({
            startDate: data.startDate ? new Date(data.startDate + "Z") : null,
            untilDate: data.untilDate ? new Date(data.untilDate + "Z") : null
          });
          setProductData(data.product);
        })
        .catch(error => console.log(error));
    }
  }, [id]);

  const getCurrencyListData = () => {
    masterApi
      .get("/Currencies", {
        signal: source.current.signal,
        params: {
          pagination: {
            pageIndex: 0,
            pageSize: 0
          },
          filter: {
            searchString: null,
            publicationStatus: 1
          }
        }
      })
      .then(data => setCurrencyList(data.records))
      .catch(error => console.log(error));
  };

  const handleProductIDChanged = e => {
    if (e) {
      setProductData({ id: e.id, name: e.name });
      setPayload(prev => ({
        ...prev,
        productID: e.id
      }));
      setShowProductModal(false);
    }
  };

  const handleCurrencyIDChanged = e => {
    if (payload.currencyID !== e.target.value) {
      setPayload(prev => ({
        ...prev,
        currencyID: e.target.value
      }));
    }
  };

  const handleStartDateChanged = e =>
    setPayload(prev => ({
      ...prev,
      startDate: e === "" ? null : e
    }));

  const handleUntilDateChanged = e =>
    setPayload(prev => ({
      ...prev,
      untilDate: e === "" ? null : e
    }));

  const handlePriceChanged = e =>
    setPayload({ ...payload, price: e.target ? e.target.value : "" });

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    const body = { ...payload };

    travelApi
      .delete("/OnlinePricings/" + id, body, {
        signal: source.current.signal
      })
      .then(() => setRedirect(true))
      .catch(err => {
        console.log(err);
        setDisabled(false);
      });
  };

  const classes = useStyles();

  return redirect ? (
    <Redirect to={"/admin/MerchantOnlineBookingPricing/List"} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              DELETE MERCHANT ONLINE BOOKING PRICING
            </h4>
          </CardHeader>
          <CardBody>
            <i>*Are you sure to delete this merchant online booking?</i>
            <br />

            <form onSubmit={handleSubmit}>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Delete
              </Button>
            </form>
            <form>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <FormControl
                    disabled={true}
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="selAgent"
                      className={classes.selectLabel}
                    >
                      Merchant Product
                    </InputLabel>
                    <Input
                      onClick={() => setShowProductModal(prev => !prev)}
                      className={classes.select}
                      value={productData ? productData.name : ""}
                    />
                    <MerchantProductDialog
                      openModal={showProductModal}
                      setOpenModal={val => setShowProductModal(val)}
                      onSelect={handleProductIDChanged}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Variant"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: editData?.variant ? editData.variant.name : "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <div style={{ height: 12 }}></div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl disabled={true} fullWidth>
                    <Datetime
                      value={payload.startDate ?? null}
                      onChange={handleStartDateChanged}
                      timeFormat={true}
                      closeOnSelect={true}
                      utc
                      inputProps={{
                        placeholder: "Start Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl disabled={true} fullWidth>
                    <Datetime
                      value={payload.untilDate ?? null}
                      onChange={handleUntilDateChanged}
                      timeFormat={true}
                      closeOnSelect={true}
                      utc
                      inputProps={{
                        placeholder: "Until Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl
                    disabled={true}
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="selCurrency"
                      className={classes.selectLabel}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      disabled={true}
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={editData?.currency ? editData.currency.id : ""}
                      onChange={handleCurrencyIDChanged}
                    >
                      {currencyList.map(currency => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={currency.id}
                            key={currency.id}
                          >
                            <b>{currency.code}</b>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Price"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handlePriceChanged,
                      value: editData && editData.price ? editData.price : "",
                      type: "number",
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

DeleteMerchantOnlineBookingPricing.propTypes = {
  history: PropTypes.object
};
