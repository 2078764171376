import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import WaitingList from "views/MerchantOnlineBooking/WaitingList";
import Detail from "views/MerchantOnlineBooking/Detail";
import MerchantOnlineBookingCompletedList from "views/MerchantOnlineBooking/CompleteList";
import MerchantOnlineBookingCompletedWithAmendmentList from "views/MerchantOnlineBooking/AmandmentList";
import MerchantOnlineBookingFailedList from "views/MerchantOnlineBooking/FailedList";
import CompleteMerchantOnlineBooking from "views/MerchantOnlineBooking/Complete";
import AmendmentMerchantOnlineBooking from "views/MerchantOnlineBooking/CompleteWithAmendment";
import FailedMerchantOnlineBooking from "views/MerchantOnlineBooking/Failed";
import { token } from "functions/Helper";

const merchantOnlineBooking = () => {
  let views = [];
  if (
    token["booking.online_bookings.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = [
      {
        path: "/MerchantOnlineBooking/WaitingForPayment",
        name: "Waiting",
        mini: "WA",
        component: WaitingList,
        layout: "/admin"
      },
      {
        path: "/MerchantOnlineBooking/CompletedList",
        name: "Completed",
        mini: "CM",
        component: MerchantOnlineBookingCompletedList,
        layout: "/admin"
      },
      {
        path: "/MerchantOnlineBooking/CompleteWithAmendmentList",
        name: "Complete With Amendment",
        mini: "CWA",
        component: MerchantOnlineBookingCompletedWithAmendmentList,
        layout: "/admin"
      },
      {
        path: "/MerchantOnlineBooking/FailedList",
        name: "Failed",
        mini: "FA",
        component: MerchantOnlineBookingFailedList,
        layout: "/admin"
      }
    ];

  return [
    {
      collapse: true,
      name: "M Online Booking",
      icon: MonetizationOnIcon,
      state: "MerchantOnlineBookingCollapse",
      views: views
    }
  ];
};

const merchantOnlineBookingHidden = () => {
  return [
    {
      collapse: true,
      name: "Merchant Online Booking Hidden",
      state: "merchantOnlineBookingHidden",
      hideInSidebar: true,
      views: [
        {
          path: "/MerchantOnlineBooking/Detail",
          name: "View Merchant Online Booking",
          component: Detail,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        },
        {
          path: "/MerchantOnlineBooking/Complete",
          name: "Complete Merchant Online Booking",
          component: CompleteMerchantOnlineBooking,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        },
        {
          path: "/MerchantOnlineBooking/Amendment",
          name: "Complete With Amendment Merchant Online Booking",
          component: AmendmentMerchantOnlineBooking,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        },
        {
          path: "/MerchantOnlineBooking/Failed",
          name: "Failed With Amendment Merchant Online Booking",
          component: FailedMerchantOnlineBooking,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        }
      ]
    }
  ];
};

export { merchantOnlineBooking, merchantOnlineBookingHidden };
